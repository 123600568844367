import { MouseEventHandler } from "react";

import cs from './button.module.scss';

interface Props {
  text: string
  onClickHandler: MouseEventHandler,
}

const Button = ({ text, onClickHandler }: Props) => {
  return(
    <button className={cs.btn} type="button" onClick={onClickHandler} >{text}</button>
  );
};

export default Button;