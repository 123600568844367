import { ko } from 'date-fns/esm/locale';
import dayjs from 'dayjs';
import ReactDatePicker from 'react-datepicker';
import { useSetRecoilState } from 'recoil';
import "react-datepicker/dist/react-datepicker.css";
import { ChangeEvent, Dispatch, FormEvent, useState } from 'react';

import cs from './researchModal.module.scss';
import useLogOut from '../../../hooks/user/useLogOut';
import { addResearch } from '../../../service/research';
import { researchState } from '../../../recoil/atoms';
import { IRequestResearch, IResearch } from '../../../types/research/index.d';

interface Props {
  setShowModal: Dispatch<React.SetStateAction<boolean>>
}

const ResearchModal = ({ setShowModal }: Props) => {

  const [title, setTitle] = useState('');
  const [selectDate, setSelectDate] = useState(new Date());

  const setResearchInfo = useSetRecoilState(researchState);
  const onLogout = useLogOut();

  const changeTitleHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setTitle(e.currentTarget.value);
  };

  const onAddSubmitHandler = (e: FormEvent) => {
    e.preventDefault();
    const data: IRequestResearch = { researchName: title, researchStartDate: dayjs(selectDate).format('YYYY-MM-DD') };
    addResearch(data)
    .then((res) => {
      const newResearch: IResearch = res.data;
      setResearchInfo((prev) => {
        return {
          ...prev,
          totalElements: prev.totalElements + 1,
          researchList: prev.currentPage !== prev.totalPages 
              ? [newResearch, ...prev.researchList].slice(0, prev.researchList.length) 
              : [newResearch, ...prev.researchList]
        };
      });
    })
    .catch(() => onLogout())
    .finally(() => {
      setShowModal(false);
    });
  };

  const onCancelHandler = () => setShowModal(false);

  return(
    <section className={cs.backBoard}>
      <div className={cs.modal}>
        <header>
          <title>연구 개설</title>
        </header>
        <main>
          <form id="create_research" onSubmit={(e) => onAddSubmitHandler(e)}>
            <input value={title} onChange={changeTitleHandler} placeholder='연구명을 입력하세요'/>
            <ReactDatePicker className={cs.datePicker} locale={ko} dateFormat='yyyy-MM-dd' selected={selectDate} onChange={(date:Date) => setSelectDate(date)} />
          </form>
        </main>
        <footer>
          <button type="submit" form="create_research">제출</button>
          <button type="button" onClick={onCancelHandler}>취소</button>
        </footer>
      </div>
    </section>
  );
};

export default ResearchModal;