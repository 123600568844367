import { useRecoilValue } from "recoil";
import { Navigate, Route, Routes } from "react-router-dom";

import './App.css';
import Login from "./routes/login";
import Essay from "./routes/essay";
import Research from "./routes/research";
import EssayDetail from "./routes/essayDetail";
import { isLoggedInState } from "./recoil/atoms";

const App = () => {

  const { isLoggedIn } = useRecoilValue(isLoggedInState);

  return(
    <Routes>
      <Route path="/login" element={ !isLoggedIn ? <Login /> : <Navigate to="/" /> }/>
      <Route>
        <Route path=":researchId" element={ isLoggedIn ? <Essay /> : <Navigate to="/login" /> } />
        <Route path=":researchId/:essayId" element={ isLoggedIn ? <EssayDetail /> : <Navigate to="/login" /> } />
      </Route>
      <Route path="/" element={ isLoggedIn ? <Research /> : <Navigate to="/login" /> }/>
      <Route path="/*" element={ isLoggedIn && <Navigate to="/" /> } />
    </Routes>
  );
};

export default App;
