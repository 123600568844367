import { authApi } from "./axios";
import { IRequestResearch } from "../types/research/index.d";

export const getResearchList = async (page: number) => {
  const result = await authApi.get(`/research?page=${page}`);
  return result;
};

export const addResearch = async (data: IRequestResearch) => {
  const result = await authApi.post('/research', data);
  return result;
};