import {CSVLink} from "react-csv";
import {useNavigate, useParams} from "react-router-dom";
import cs from './essay.module.scss';
import Toggle from "../../components/common/toggle";
import Header from "../../components/common/header";
import {useMount} from "react-use";
import {getEssayListByPos, getEssayListByPsyPos} from "../../service/essay";
import {useState} from "react";
import {IEssayResultByPos, IEssayResultByPsyPos} from "../../types/essay/index.d";
import {
  RESULT_OBJ_BY_POS,
  RESULT_OBJ_BY_PSYPOS,
  RESULT_TYPE_BY_POS,
  RESULT_TYPE_BY_PSYPOS
} from "../../constant/resultType";
import useLogOut from "../../hooks/user/useLogOut";
import EssayModal from "../../components/essay/modal";
import {makeCsvListData} from "../../utils/csv";


const Essay = () => {
  const [isSelect, setIsSelect] = useState('lang');
  const [showModal, setShowModal] = useState(false);
  const [essayListByPos, setEssayListByPos] = useState<IEssayResultByPos[]>();
  const [essayListByPsyPos, setEssayListByPsyPos] = useState<IEssayResultByPsyPos[]>();
  const params = useParams();

  const onLogout = useLogOut();
  const nav = useNavigate();

  useMount(() => {
    getEssayListByPos(Number(params.researchId))
      .then((res) => setEssayListByPos(res.data))
      .catch(() => onLogout());
    getEssayListByPsyPos(Number(params.researchId))
      .then((res) => setEssayListByPsyPos(res.data))
      .catch(() => onLogout());
  });

  const onShowModal = () => setShowModal(true);

  const onEssayClick = (essayId: number) => {
    nav(`${essayId}`);
  };

  return (
    <section>
      <header><Header/></header>
      <main>
        <section className={cs.titleSection}>
          <title className={cs.title}>에세이 목록</title>
        </section>
        <div className={cs.postInfo}>
          <Toggle isSelect={isSelect} setIsSelect={setIsSelect}/>
          <aside className={cs.btnWrapper}>
            <CSVLink
              filename={`${isSelect}_result_list_research_id_${params.researchId}`}
              className={cs.csvBtn} type='button' data={
              makeCsvListData(isSelect === 'lang' ? essayListByPos : essayListByPsyPos, isSelect)}>전체 다운</CSVLink>
            <button type='button' onClick={onShowModal}>+ 에세이 개설</button>
          </aside>
        </div>
        <section className={cs.itemListSection}>
          <table>
            <thead>
              <tr>
                <th>제목</th>
                {isSelect === 'phy'
                ? RESULT_TYPE_BY_PSYPOS.map((value, index) =>
                  <th key={`essay_result_phy_${index + 1}`}>
                    {RESULT_OBJ_BY_PSYPOS[value]}
                  </th>)
                : RESULT_TYPE_BY_POS.map((value, index) =>
                  <th key={`essay_result_pos_${index + 1}`}>
                    {RESULT_OBJ_BY_POS[value]}
                  </th>)
              }
              </tr>
            </thead>
            <tbody>
              {isSelect === 'phy'
              ? essayListByPsyPos?.map((value) =>
                <tr key={`essay_tr_phy_${value.id}`} onClick={() => onEssayClick(value.id)}>
                  <td>{value.title}</td>
                  {RESULT_TYPE_BY_PSYPOS.map(val =>
                    <td key={`essay_td_phy_${val}`}>{value.psyPosCountingDto[val]}</td>
                  )}
                </tr>)
              : essayListByPos?.map((value) =>
                <tr key={`essay_tr_pos_${value.id}`} onClick={() => onEssayClick(value.id)}>
                  <td>{value.title}</td>
                  {RESULT_TYPE_BY_POS.map(val =>
                    <td key={`essay_td_pos_${val}`}>{value.posCountingDto[val]}</td>
                  )}
                </tr>)}
            </tbody>
          </table>
        </section>
      </main>
      { showModal && <EssayModal researchId={Number(params.researchId)} setShowModal={setShowModal} /> }
    </section>
  );
};

export default Essay;