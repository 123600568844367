import {
  RESULT_OBJ_BY_POS,
  RESULT_OBJ_BY_PSYPOS,
  RESULT_TYPE_BY_POS,
  RESULT_TYPE_BY_PSYPOS,
} from "../constant/resultType";

export const makeCsvListData = (data: any, type: any) => {
  if(data && data.length !== 0 ) {
    const columnList: string[] = type === 'phy' ? RESULT_TYPE_BY_PSYPOS : RESULT_TYPE_BY_POS;
    const columnListByKR: string[] = type === 'phy'
        ? RESULT_TYPE_BY_PSYPOS.map((column) => RESULT_OBJ_BY_PSYPOS[column])
        : RESULT_TYPE_BY_POS.map((column) => RESULT_OBJ_BY_POS[column]);
    const csvResult = [['essayId', ...columnListByKR], []];
    data.forEach((item: any, index: any) => {
      const resultList = columnList.map((column) => {
        if(type === 'phy') {
          return item.psyPosCountingDto[column];
        } 
          return item.posCountingDto[column];
        
      });
      csvResult[index + 1] = [item.id, ...resultList];
    });
    return csvResult;
  }
    return [];
};

export const makeCsvDataByPos = (data: any) => {
  if(data) {
   return RESULT_TYPE_BY_POS.map((column: any) => [RESULT_OBJ_BY_POS[column], data[column]]);
  }
  return [];
};

export const makeCsvDataByPsyPos = (data: any) => {
  if(data) {
    return RESULT_TYPE_BY_PSYPOS.map((column: any) =>  [RESULT_OBJ_BY_PSYPOS[column], data[column]]);
  }
  return [];
};