import store from 'storejs';
import { atom } from "recoil";

import { IUser } from '../types/user/index.d';
import { IResearchState } from '../types/research/index.d';

const user = store.get(String(process.env.REACT_APP_LOCAL_USER_KEY)) || { isLoggedIn: false } as IUser;

const INIT_RESEARCH_STATE: IResearchState = {
  currentPage: 0,
  totalElements: 0,
  totalPages: 0,
  researchList: [],
};

export const isLoggedInState = atom({
  key: 'isLoggedInState',
  default: user,
});

export const researchState = atom({
  key: 'researchState',
  default: INIT_RESEARCH_STATE
});