import cs from './essayDetail.module.scss';
import Header from '../../components/common/header';
import {useMount} from 'react-use';
import {getEssay, getEssayResultByPos, getEssayResultByPsyPos} from '../../service/essay';
import {useState} from 'react';
import {IEssay} from '../../types/essay/index.d';
import useLogOut from '../../hooks/user/useLogOut';
import {useParams} from "react-router-dom";
import {CSVLink} from "react-csv";
import {makeCsvDataByPos, makeCsvDataByPsyPos} from "../../utils/csv";

const EssayDetail = () => {

  const [essayDetail, setEssayDetail] = useState<IEssay>({} as IEssay);
  const [psyPosData, setPsyPosData] = useState();
  const [posData, setPosData] = useState();

  const onLogout = useLogOut();

  const {essayId} = useParams();

  useMount(() => {
    getEssay(Number(essayId))
      .then((res) => {
        setEssayDetail(res.data);
      })
      .catch(() => onLogout());
    getEssayResultByPos(Number(essayId))
      .then((res) => {
        setPosData(res.data);
      });
    getEssayResultByPsyPos(Number(essayId))
      .then((res) => {
        setPsyPosData(res.data);
      });
  });

  return (
    <section>
      <header><Header/></header>
      <main>
        <section className={cs.titleSection}>
          <title className={cs.title}>에세이 상세</title>
        </section>
        <article className={cs.essay}>
          <title>{essayDetail.essayName}</title>
          <div className={cs.subInfo}>
            <span>{essayDetail.essayType}</span>
            <span>{essayDetail.essayAuthor}</span>
          </div>
          <main>
            {essayDetail.essayContent}
            <aside className={cs.downSide}>
              <CSVLink filename={`pos_result_id_${essayId}`} data={makeCsvDataByPos(posData)} type='button'>언어결과</CSVLink>
              <CSVLink filename={`psyPos_result_id_${essayId}`} data={makeCsvDataByPsyPos(psyPosData)} type='button'>심리결과</CSVLink>
            </aside>
          </main>
          <footer>
            <p>메모</p>
            {essayDetail.essayMemo}
          </footer>
        </article>
      </main>
    </section>
  );
};

export default EssayDetail;