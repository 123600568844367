import  GoogleLogin from 'react-google-login';

import cs from './login.module.scss';
import useLogin from '../../hooks/user/useLogin';

const Login = () => {

  const onSuccessHandler = useLogin();

  return(
    <section className={cs.loginSection}>
      <title>Psychological-Language-Analysis-System</title>
      <GoogleLogin 
        className={cs.button}
        clientId={String(process.env.REACT_APP_GOOGLE_LOGIN_KEY)}
        buttonText='로그인' 
        onSuccess={onSuccessHandler} 
        cookiePolicy='single_host_origin'
      /> 
    </section>
  );
};

export default Login;