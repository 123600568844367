import store from 'storejs';
import { useResetRecoilState, useSetRecoilState } from 'recoil';

import { IUser } from '../../types/user/index.d';
import { authApi } from '../../service/axios';
import { IHeadersDefaults } from '../../types/axios/index.d';
import { isLoggedInState, researchState } from '../../recoil/atoms';


const useLogOut = () => {

  const setIsLoggedInState = useSetRecoilState(isLoggedInState);
  const resetResearchListState = useResetRecoilState(researchState);
  // const resetEssayListState = useResetRecoilState(essayLi) TODO Reset Essay State

  const logOutHandler = () => {
    store.remove(String(process.env.REACT_APP_LOCAL_USER_KEY));
    setIsLoggedInState({ isLoggedIn: false } as IUser);
    authApi.defaults.headers = { accessToken : `` } as IHeadersDefaults;
    resetResearchListState();
  };

  return logOutHandler;
};

export default useLogOut;