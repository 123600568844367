import store from 'storejs';
import { useSetRecoilState } from 'recoil';
import { GoogleLoginResponseOffline, GoogleLoginResponse } from "react-google-login";

import { login } from '../../service/login';
import { IUser } from '../../types/user/index.d';
import { authApi } from '../../service/axios';
import { isLoggedInState } from '../../recoil/atoms';
import { IHeadersDefaults } from '../../types/axios/index.d';

const useLogin = (): (res: GoogleLoginResponseOffline | GoogleLoginResponse) => void => {

  const setLoggedInState = useSetRecoilState(isLoggedInState);

  const onSuccessHandler = (res: GoogleLoginResponseOffline | GoogleLoginResponse) => {
    if ("profileObj" in res) {
      const loginInfo: IUser = {
        email: res.profileObj.email,
        name: res.profileObj.name,
        accessToken: res.accessToken,
        isLoggedIn: true,
        profile: res.profileObj.imageUrl.replace("96", "32"),
      };
      login(loginInfo)
      .then((response) => {
        store.set(String(process.env.REACT_APP_LOCAL_USER_KEY), {...loginInfo, accessToken: response.data.accessToken});
        authApi.defaults.headers = { accessToken : `Bearer ${response.data.accessToken}` } as IHeadersDefaults;
        setLoggedInState(loginInfo);
      })
      .catch(() => { // login Error
        localStorage.clear();
        store.clear();
      });
    }
  };

  return onSuccessHandler;
};

export default useLogin;