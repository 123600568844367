import { MouseEvent } from 'react';

import cs from './toggle.module.scss';

interface Props {
  isSelect: string,
  setIsSelect: Function
}

const Toggle = ({ isSelect, setIsSelect }: Props) => {

  const onToggleHandle = (e: MouseEvent<HTMLElement>) => {
    const { id } = e.currentTarget.dataset;
    setIsSelect((prev: string) => {
      return id || prev;
    });
  };

  return(
    <nav className={cs.back}>
      <button type='button' data-id='lang' className={String(isSelect === 'lang' && cs.select)} onClick={onToggleHandle}>언어</button>
      <button type='button' data-id='phy' className={String(isSelect === 'phy' && cs.select)} onClick={onToggleHandle}>심리</button>
    </nav>
  );
};

export default Toggle;