import "react-datepicker/dist/react-datepicker.css";
import {ChangeEvent, Dispatch, SetStateAction, useState} from 'react';

import cs from './essayModal.module.scss';
import useLogOut from '../../../hooks/user/useLogOut';
import {addEssay} from "../../../service/essay";
import {useNavigate} from "react-router-dom";

interface Props {
  researchId: number;
  setShowModal: Dispatch<SetStateAction<boolean>>
}

const EssayModal = ({ researchId, setShowModal }: Props) => {

  const onLogout = useLogOut();
  const nav = useNavigate();

  const [inputs, setInputs] = useState({
    name: '',
    memo: '',
    author: '',
    from: '',
    type: '',
    content: '',
    file: {},
  });

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {value, name} = e.currentTarget; // 우선 e.target 에서 name 과 value 를 추출
    if (name === "file" && 'files' in e.currentTarget && e.currentTarget.files) {
      setInputs({
        ...inputs,
        file: e.currentTarget.files[0]
      });
    } else {
      setInputs({
        ...inputs, // 기존의 input 객체를 복사한 뒤
        [name]: value // name 키를 가진 값을 value 로 설정
      });
    }
  };

  const onSubmitEssayModal = (e: any) => {
    e.preventDefault();
    const {name, memo, author, from, type, content, file} = inputs;
    const formData = new FormData();
    formData.append("essayName", name);
    formData.append("memo", memo);
    formData.append("essayAuthor", author);
    formData.append("essayFrom", from);
    formData.append("essayType", type);
    if (content !== '') {
      formData.append("essayContent", content);
    } else {
      formData.append("file", file as Blob);
    }
    setInputs({
      name: '',
      memo: '',
      author: '',
      from: '',
      type: '',
      content: '',
      file: {}
    });
    addEssay(formData, researchId)
      .then(() => {
        nav(`/${researchId}`);
      })
      .catch(() => onLogout())
      .finally(() => {
        setShowModal(false);
      });
  };

  const onCancelHandler = () => setShowModal(false);

  return(
    <section className={cs.backBoard}>
      <div className={cs.modal}>
        <header>
          <title>에세이 생성</title>
        </header>
        <main>
          <form id="create_essay" onSubmit={onSubmitEssayModal}>
            <input name='name' value={inputs.name} onChange={onChange} placeholder='에세이명을 입력하세요'/>
            <input name='author' value={inputs.author} onChange={onChange} placeholder='저자를 입력하세요'/>
            <input name='type' value={inputs.type} onChange={onChange} placeholder='타입을 입력하세요'/>
            <textarea name='memo' value={inputs.memo} onChange={onChange} placeholder='메모를 입력하세요'/>
            <textarea name='content' value={inputs.content} onChange={onChange} placeholder='내용을 입력하세요'/>
            <input name='from' value={inputs.from} onChange={onChange} placeholder='출처를 입력하세요'/>
            {inputs.content === '' &&
              <input style={{paddingTop: 0, marginBottom: 0}} name="file" type="file" onChange={onChange}
                     accept='.txt'
                     placeholder="에세이 종류를 입력하세요"/>}
          </form>
        </main>
        <footer>
          <button type="submit" form="create_essay">제출</button>
          <button type="button" onClick={onCancelHandler}>취소</button>
        </footer>
      </div>
    </section>
  );
};

export default EssayModal;