export const RESULT_TYPE_BY_PSYPOS = [
  "psySentence",
  "psyWord",
  "morphem",
  "wpers",
  "mpers",
  "a1",
  "a11",
  "a111",
  "a112",
  "a113",
  "a114",
  "a12",
  "a121",
  "a122",
  "a123",
  "a124",
  "a125",
  "b1",
  "b11",
  "b12",
  "b13",
  "b14",
  "b141",
  "b142",
  "b143",
  "b15",
  "b16",
  "b17",
  "c1",
  "c11",
  "c12",
  "c13",
  "c14",
  "c15",
  "c16",
  "d1",
  "d11",
  "d12",
  "d13",
  "d14",
  "d15",
  "d16",
  "e1",
  "e11",
  "e111",
  "e112",
  "e113",
  "e114",
  "e115",
  "e12",
  "e121",
  "e122",
  "e123",
  "e124",
  "e125",
  "e2",
  "e21",
  "e22",
  "f1",
  "f11",
  "f12",
  "f13",
  "g1",
  "g11",
  "g12",
  "g13",
  "g14",
  "h1",
  "i1",
  "i11",
  "i12",
  "i13",
  "i14",
  "j1",
  "j11",
  "j12",
  "k1",
  "l1",
  "m1",
  "n1",
  "z",
];

export const RESULT_OBJ_BY_PSYPOS: any = {
  psySentence: "문장",
  psyWord: "단어",
  morphem: "형태소",
  wpers: "단어/형태소",
  mpers: "형태소/문장",
  a1: "감정또는정서적과정",
  a11: "긍정적인 정서",
  a111: "기쁨/즐거움",
  a112: "만족/안도",
  a113: "복합긍정",
  a114: "긍정적인 느낌",
  a12: "부정적인 정서",
  a121: "불안",
  a122: "화",
  a123: "슬픔 또는 우울",
  a124: "복합부정",
  a125: "부정적인 느낌",
  b1: "인지적인과정",
  b11: "원인",
  b12: "사고",
  b13: "기대",
  b14: "제한",
  b141: "억제(inhibition)",
  b142: "포함(include)",
  b143: "제외(exclude)",
  b15: "추측",
  b16: "확신",
  b17: "차이",
  c1: "감각지각적인 과정",
  c11: "시각",
  c12: "청각",
  c13: "촉각/체감각",
  c14: "미각",
  c15: "후각",
  c16: "고유감각",
  d1: "사회적 과정",
  d11: "체면",
  d12: "의사소통",
  d13: "타인참조",
  d14: "또래친구",
  d15: "가족",
  d16: "인간",
  e1: "동기",
  e11: "접근동기",
  e111: "소속감",
  e112: "성취",
  e113: "권력",
  e114: "보상",
  e115: "위험",
  e12: "회피동기",
  e121: "소속감",
  e122: "성취",
  e123: "권력",
  e124: "보상",
  e125: "위험",
  e2: "학교/직장인",
  e21: "학교",
  e22: "직장일",
  f1: "시간",
  f11: "과거",
  f12: "현재",
  f13: "미래",
  g1: "여가활동",
  g11: "집",
  g12: "운동",
  g13: "TV영화",
  g14: "음악",
  h1: "돈재정적 이슈",
  i1: "신체적 상태와 기능",
  i11: "몸 상태와 증상",
  i12: "성성징",
  i13: "식사음주다이어트",
  i14: "수면꿈",
  j1: "형이상학적 이슈",
  j11: "죽음",
  j12: "종교",
  k1: "속어",
  l1: "맹세어절",
  m1: "어눌한 말",
  n1: "신조어",
  z: "미분류",
};

export const RESULT_TYPE_BY_POS = [
  "posSentence",
  "pstWord",
  "morphem",
  "wpers",
  "mpers",
  "ncpa",
  "ncps",
  "ncn",
  "ncr",
  "nqpa",
  "nqpb",
  "nqpc",
  "nqq",
  "nbn",
  "nbs",
  "nbu",
  "nnc",
  "nno",
  "npp",
  "npd",
  "pvd",
  "pvg",
  "pad",
  "paa",
  "px",
  "jp",
  "mmd",
  "mma",
  "mad",
  "mag",
  "maj",
  "ii",
  "jcs",
  "jcc",
  "jcm",
  "jco",
  "jca",
  "jcv",
  "jcr",
  "jct",
  "jcj",
  "jxc",
  "jxf",
  "ep",
  "ef",
  "ecc",
  "ecs",
  "ecx",
  "etn",
  "etm",
  "xp",
  "xsnu",
  "xsna",
  "xsnca",
  "xsncc",
  "xsns",
  "xsnp",
  "xsnx",
  "xsvv",
  "xsva",
  "xsvn",
  "xsms",
  "xsmn",
  "xsam",
  "xsas",
  "sf",
  "sp",
  "sl",
  "sr",
  "se",
  "sd",
  "su",
  "sy",
  "f",
];

export const RESULT_OBJ_BY_POS: any = {
  posSentence: "문장",
  pstWord : "단어",
  morphem: "형태소",
  wpers: "단어/형태소",
  mpers: "형태소/문장",
  ncpa: "동작성 명사",
  ncps: "상태성 명사",
  ncn: "비서술성 명사",
  ncr: "비서술성 작위명사",
  nqpa: "성",
  nqpb: "이름",
  nqpc: "성+이름",
  nqq: "기타(일반)",
  nbn: "비단위성 의존명사",
  nbs: "비단위성 의존명서(~하다)",
  nbu: "단위성 의존명사",
  nnc: "양수사",
  nno: "서수사",
  npp: "인칭대명사",
  npd: "지시대명사",
  pvd: "지시동사",
  pvg: "일반동사",
  pad: "지시형용사",
  paa: "성상형용사",
  px: "보조용언",
  jp: "서술격 조사",
  mmd: "지시관형사",
  mma: "성상관형사",
  mad: "지시부사",
  mag: "일반부사",
  maj: "접속부사",
  ii: "감탄사",
  jcs: "주격 조사",
  jcc: "보격 조사",
  jcm: "관형격 조사",
  jco: "목적격 조사",
  jca: "부사격 조사",
  jcv: "호격 조사",
  jcr: "인용격 조사",
  jct: "공동격 조사",
  jcj: "접속격 조사",
  jxc: "통용 보조사",
  jxf: "종결 보조사",
  ep: "선어말 어미",
  ef: "종결 어미",
  ecc: "대등적 연결어미",
  ecs: "종속적 연결어미",
  ecx: "보조격 연결어미",
  etn: "명사형 전성어미",
  etm: "관형사형 전성어미",
  xp: "접두사",
  xsnu: "단위 뒤 명사파생 접미사",
  xsna: "동작성 뒤 명사파생 접미사",
  xsnca: "일반명사 뒤 명사파생 접미사",
  xsncc: "일반명사 뒤 명사파생 접미사",
  xsns: "상태성 뒤 명사파생 접미사",
  xsnp: "인칭대명사 1, 3뒤 명사파생 접미사",
  xsnx: "모든 명사 뒤 명사파생 접미사",
  xsvv: "동사 뒤 동사파생 접미사",
  xsva: "동작 명사 뒤 동사파생 접미사",
  xsvn: "일반 명사 뒤 동사파생 접미사",
  xsms: "상태 명사 뒤 형용사파생 접미사",
  xsmn: "일반 명사 뒤 형용사파생 접미사",
  xsam: "형용사 뒤 부사파생 접미사",
  xsas: "상태명사 뒤 부사파생 접미사",
  sf: "마침표",
  sp: "쉼표",
  sl: "여는 따옴표 및 묶음표",
  sr: "닫은 따옴표 및 묶음표",
  se: "줄임표",
  sd: "이음표",
  su: "단위기호",
  sy: "기타기호",
  f: "외국어",
};