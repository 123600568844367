import { useRecoilValue } from 'recoil';
import { Link, NavLink } from 'react-router-dom';

import cs from './header.module.scss';
import useLogOut from '../../../hooks/user/useLogOut';
import { isLoggedInState } from '../../../recoil/atoms';

const Header = () => {

  const userInfo = useRecoilValue(isLoggedInState);
  const logOutHandler = useLogOut();

  return(
    <section className={cs.base}>
      <title><Link to='/'>PLAS</Link></title>
      <aside>
        <nav className={cs.navList}>
          <NavLink to='/' className={cs.item}>Research</NavLink>
        </nav>
        <button style={{backgroundImage: `url(${userInfo.profile})`}}
         type='button' onClick={logOutHandler} aria-label='profile_btn'/>
      </aside>
    </section>
  );
};

export default Header;